<script>
import { setDefaultMeta } from "@/composables/useMeta";

export default {
  data: () => ({
    mounted: false,
  }),
  mounted() {
    setDefaultMeta();
    this.mounted = true;
  },
};
</script>

<template>
  <div class="error">
    <div class="error__image" loading="lazy" v-if="mounted"></div>
    <div class="error__content">
      <div class="error__caption">We're having technical issues</div>
      <div class="error__sub-caption">Please try to refresh the page</div>
      <div class="error__description">
        If you’re still having problems, please come back later. Sorry for the
        inconvenience.
      </div>
      <div class="error__logo-wrapper">
        <img
          class="error__logo"
          loading="lazy"
          v-if="mounted"
          src="../static/images/error_logo.svg"
        />
      </div>
      <div class="error__image-credits">Photo by Sarah Kilian on Unsplash</div>
    </div>
  </div>
</template>

<style>
.error {
  width: 100%;
  height: 99vh;
  margin: 0;
  padding: 0;
}

.error__image {
  width: 100%;
  height: 25%;
  background-image: url("../static/images/error_header.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.error__content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 75%;
  padding: 0 1rem;
}

.error__caption {
  text-align: center;
  font-size: 39px;
  font-weight: 700;
  color: #1a1a1a;
}

.error__sub-caption {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  color: #1a1a1a;
  margin-bottom: -1%;
}

.error__description {
  text-align: center;
  font-size: 16px;
  color: #1a1a1a;
}

.error__logo-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 2%;
}

.error__logo {
  max-width: 165px;
}

.error__image-credits {
  text-align: center;
  font-size: 12px;
  color: #797b80;
}

@media (--tabletAndDesktop) {
  .error__image {
    width: 100%;
    height: 60%;
    background-position-y: 100%;
  }

  .error__content {
    height: 40%;
  }
}
</style>
